


































import Vue from 'vue';
import gsap, { Power1, Power2 } from 'gsap';


export default Vue.extend({
    name: 'MainMenu',
    methods: {
        toggleNav( action = 'toggle' ) {
            const element = this.$refs.mainMenu as HTMLElement;

            if( action == 'show' ) {
                this.showNav( element );
                return;
            }

            if( action == 'hide' ) {
                this.hideNav( element );
                return;
            }

            const isHidden = +window.getComputedStyle(element).left.slice(0,1) > 0;

            if( isHidden )
                this.showNav( element );
            else
                this.hideNav( element );
        },
        hideNav( element: HTMLElement ) {
            const linkelements  = [...element.querySelectorAll('.main-menu-menu-ul-li a')];
            const angleElements = [...element.querySelectorAll('.angle')];
            const toggler       = document.querySelector('.main-menu-toggler') as HTMLElement;
            toggler.classList.remove('closable');

            const tl  = gsap.timeline();

            tl.to( toggler, .6, {transform: 'rotateZ(-90deg)', color: '#fff',  ease: Power1.easeInOut}, 0 )
            angleElements.forEach( elm => tl.to( elm, .6, {width: '0', ease: Power2.easeIn},  '<' ) )
            linkelements.forEach( elm => tl.to( elm, .6, {opacity: '0', ease: Power2.easeOut}, '-=.45' ) )
            tl.to( element, .3, {height: '50vh', top: '25vh', ease: Power2.easeIn}, '<' )
            tl.to( element,  .35, {left: '100vw', ease: Power2.easeOut}, '<' )
        },
        showNav( element: HTMLElement ) {
            const linkelements  = [...element.querySelectorAll('.main-menu-menu-ul-li a')];
            const angleElements = [...element.querySelectorAll('.angle')];
            const toggler       = document.querySelector('.main-menu-toggler') as HTMLElement;
            toggler.classList.add('closable');

            const tl  = gsap.timeline();

            tl.to( toggler, .6, {color: 'transparent',  ease: Power1.easeOut}, 0 )
            tl.to( toggler, .6, {transform: 'rotateZ(-90deg)', ease: Power1.easeIn}, '-=1' )
            tl.to( element, .6, {left: 0, ease: Power1.easeOut}, '<' )
            tl.to( element, {height: '100vh', top: 0,  ease: Power1.easeIn}, '-=.4' )
            angleElements.forEach( elm => tl.to( elm, .6, {width: '35vw', ease: Power1.easeIn},  '<' ) )
            linkelements.forEach( elm => tl.to( elm, .6, {opacity: '1', ease: Power1.easeIn},  '-=.3' ) )
        }
    }
})
