









import Vue from 'vue';

export default Vue.extend({
    name: 'Testimonial',
    props: {
        testimonial: {
            type: Object
        }
    }
})
