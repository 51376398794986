











import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default Vue.extend({
    name: 'KrakenButton',
    props: {
        buttonText: {
            type: String,
            default: 'K',
            validator( text ) {
                return text.length === 1
            }
        },
        buttonLink: {
            type: String,
            default: '/'
        },
        isAnchor: Boolean
    },
    methods: {
        navigate() {
            if ( this.$route.path !== this.buttonLink )
                this.$router.push({ path: this.buttonLink })
        }
    }
})
