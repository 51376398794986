











import Vue from 'vue';
import Testimonial from '@/components/ui/testimonials/Testimonial.vue';
import gsap, { Power2 } from 'gsap';

export default Vue.extend({
    name: 'Testimonials',
    components: {
        Testimonial
    },
    props: {
        testimonials: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            computedTestimonials: [],
            testimonialsPerPage: 3
        }
    },
    methods: {
        setCurrentPage( page: number, event: MouseEvent | null = null ) {
            if( event ) {
                const bulletElement = event.target as HTMLElement;
                [...document.querySelectorAll('.testimonials-testimonial-bullet')].forEach( elm => elm.classList.remove('testimonials-active') );
                bulletElement.classList.add('testimonials-active');
            } else {
                this.$data.computedTestimonials = this.testimonials.slice(page * this.$data.testimonialsPerPage,(page + 1) * this.$data.testimonialsPerPage)
            }

            this.animateChange();

            setTimeout(() => {
                this.$data.computedTestimonials = this.testimonials.slice(page * this.$data.testimonialsPerPage,(page + 1) * this.$data.testimonialsPerPage)
            }, 600);
        },
        animateChange() {
            const tl = gsap.timeline();
            const textElements     = [...document.querySelectorAll('.testimonials-testimonial-element')];
            textElements.forEach( (elm, i) => tl.to( elm, .3, {color: 'transparent', bottom: '-30vh',  opacity: 0, ease: Power2.easeIn},  i === 0 ? 0 : '-=.1' ) )
            textElements.forEach( elm => tl.to( elm, .3, {color: '#fff', bottom: 0,  opacity: 1, ease: Power2.easeInOut}, '-=.1' ) )
        }
    },
    created() {
        this.setCurrentPage(0);
    }
})
