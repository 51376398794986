













import Vue from 'vue'

export default Vue.extend({
    name: 'MainTitle',
    props: {
        titleText: String,
        subTitleText: String
    }
})
