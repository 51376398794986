












import Vue from 'vue';
import gsap, { Power3 } from 'gsap';

export default Vue.extend({
    name: 'ContactBanner',
    methods: {
        animateHover( changedText = '' ) {
            const tl = gsap.timeline();
            const overlayBElement = document.querySelector('.contact-banner-overlay-b');
            const overlayWElement = document.querySelector('.contact-banner-overlay-w');
            
            tl.to( overlayBElement, .4, {height: '140%', opacity: '1', ease: Power3.easeOut}, 0 );
            tl.to( overlayWElement, .4, {height: '140%', opacity: '1', ease: Power3.easeOut}, '-=.15' );

            tl.add( () =>  this.setTitleText( changedText ) );

            tl.to( overlayBElement, .3, {width: 0, left: '200%', ease: Power3.easeIn}, '>' );
            tl.to( overlayWElement, .3, {width: 0, left: '200%', ease: Power3.easeIn}, '<' );
            
            tl.to( overlayWElement, .1, {width: '110%', left: '-5%' , top: '-30%', height: 0}, '>' );
            tl.to( overlayBElement, .1, {width: '110%', left: '-5%' , top: '-30%', height: 0}, '<' );
        },
        setTitleText( text = '' ) {
            const textElement     = document.querySelector('.contact-banner-title-element') as HTMLSpanElement;
            textElement.innerHTML = text; 
        }
    }
})
