

























import Vue from 'vue';
import Router from 'vue-router';
import gsap, { Power2 } from 'gsap';
import { works } from '@/data/works.ts';
import { vw } from '@/utils/deviceDetect';

Vue.use( Router );

export default Vue.extend({
    name: 'ProjectsList',
    data() {
        return {
            hoverElement: null,
            hoverImage: null,
            projects: works
        }
    },
    methods: {
        imageHover( event: MouseEvent, image: string ) {
            const hoverElement = document.querySelector('.projectlist-list-hovercard-wrapper') as HTMLDivElement;
            const hoverImage   = document.querySelector('.projectlist-list-hovercard-image') as HTMLImageElement;
            const hoverTitle   = document.querySelector('.projectlist-list-hovercard-text-title') as HTMLDivElement;
            const actualLi     = (event.target as HTMLDivElement).parentElement as HTMLDivElement;
            const hoverDesc    = document.querySelector('.projectlist-list-hovercard-text-desc') as HTMLDivElement;

            hoverImage.src = image;

            hoverTitle.innerHTML = actualLi.getAttribute('data-excerpt') as string;
            hoverDesc.innerHTML  = actualLi.getAttribute('data-projectType') as string;

            this.moveElementToMousePosition( event, hoverElement )

        },
        moveElementToMousePosition( event: MouseEvent, element: HTMLDivElement | HTMLElement ) {
            gsap.to( element, { opacity: 1, top: event.clientY + 'px', left: event.clientX + (element.offsetWidth * 1.3) + 'px', ease: Power2.easeOut})
        },
        setMovingHover() {
            this.$data.hoverElement = document.querySelector('.projectlist-list-hovercard-wrapper') as HTMLDivElement;
            this.$data.hoverImage   = document.querySelector('.projectlist-list-hovercard-image') as HTMLImageElement;
        },
        hideMovingHover() {
            gsap.to( this.$data.hoverElement, {opacity: 0, ease: Power2.easeOut})
        }
    },
    mounted() {
        if( vw > 768 )
            this.setMovingHover();
    }
})
