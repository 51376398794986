<template>
  <div id="app">
    <BlockCurtain />
    <MainMenu />
    <transition name="router-anim" v-on:enter="transitionIn" v-on:leave="transitionOut">
      <router-view/>
    </transition>

    <Cookies />
  </div>
</template>

<script>
import MainMenu from '@/components/ui/MainMenu.vue';
import BlockCurtain from '@/components/ui/BlockCurtain.vue';
import Cookies from '@/components/interaction/Cookies.vue';
import gsap, { Power3, Power4 } from 'gsap';

export default {
  name: 'app',
  components: {
    MainMenu,
    BlockCurtain,
    Cookies
  },
  methods: {
    transitionIn( el, done ) {
      const curtainElements = [... document.querySelectorAll('.block-curtain-block') ];
      const tl              = gsap.timeline({ onComplete: done });
      
      tl.set(el, { autoAlpha: 0})

      curtainElements.forEach( (elm, i) => tl.to( elm, .3, { height: 0, color: 'transparent', ease: Power3.easeIn, delay: i === 0 ? 1 : 0 }, i === 0 ? 0 : '-=.1'));

      tl.to(el, .5, { ease: Power4.easeIn, onComplete: done, autoAlpha: 1, }, '-=.9');

    },
    transitionOut( el, done ) {
      const curtainElements = [... document.querySelectorAll('.block-curtain-block') ];
      const tl              = gsap.timeline();

      curtainElements.forEach( elm => tl.set( elm, { color: '#3B3D45' } ) );

      curtainElements.forEach( elm => tl.to( elm, .3, { height: '100vh', ease: Power3.easeOut}, '-=.1'));

      tl.to(el, 0, { onComplete: done, opacity: 0, }, '<');
    }
  }
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

html {
  background: linear-gradient(to bottom, #3B3D45 5vh,  #121315 100%);
  scroll-behavior: smooth;
}

body {
  background: url(./assets/img/noise.png);
  animation: noise .2s infinite steps(3);

  &::-webkit-scrollbar {
    background-color:  #3B3D45;
    max-width: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background-color:  #fff;
  }

}

@keyframes noise {
  to {
    background-position: 1%;
  }
}

#app {
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Rubik', sans-serif;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.dark-link {
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration: none;
    display: block;
    color:  #2c3e50;
    margin-top: .3rem;
    transition: all .25s;

    &:hover {
      color: rgb(230, 172, 49);
    }
}

.swal2-content {
  font-family: 'Rubik', sans-serif;
  & a {
    font-family: 'Rubik', sans-serif;
  }
}

</style>
