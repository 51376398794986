















import Vue from 'vue';
import * as PIXI from 'pixi.js';
import { vw } from '@/utils/deviceDetect';

export default Vue.extend({
    name: 'QuotesImage',
    props: {
        quoteText: String,
        quoteAuthor: String,
        quoteImage: String,
        imgLast: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        enable3dEffect() {
            const elementWrapper = document.querySelector('.quotesimage-wrapper') as HTMLDivElement;
            const imageWrapper   = document.querySelector('.quotesimage-image-wrapper') as HTMLDivElement;
            const canvasElement   = document.querySelector('.quotesimage-image') as HTMLCanvasElement;
            
            const app = new PIXI.Application({ width: window.innerWidth / 1.5, height: window.innerWidth / 1.5, transparent: true, view: canvasElement });
            
            app.view.classList.add('quotesimage-image');
            imageWrapper.appendChild( app.view );

            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const mapImage     = require(`@/assets/img/${ this.$props.quoteImage }`);
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const deahMapImage = require('@/assets/img/brain-mapb.png');

            const img  = PIXI.Sprite.from( mapImage );
            img.width  = window.innerWidth / 1.5 ;
            img.height = window.innerWidth / 1.5;
            img.anchor.x = 0;
            img.anchor.y = 0;
            app.stage.addChild( img );

            const deathMap = PIXI.Sprite.from( deahMapImage );
            deathMap.width  = window.innerWidth / 1.5 ;
            deathMap.height = window.innerWidth / 1.5;
            deathMap.anchor.x = 0;
            deathMap.anchor.y = 0;
            app.stage.addChild( deathMap );

            const displacementFilter = new PIXI.filters.DisplacementFilter( deathMap )
            app.stage.filters = [ displacementFilter ];

            if( vw > 992 ) {
                elementWrapper.onmousemove = ( event: MouseEvent ) => {
                    displacementFilter.scale.x = ( elementWrapper.offsetWidth / 2 - event.clientX ) / 18;
                    displacementFilter.scale.y = ( elementWrapper.offsetHeight / 2 - event.clientY ) / 18;
                }
            }
        }
    },
    mounted() {
        setTimeout(() => {
            if( vw > 992 )
                this.enable3dEffect();
        }, 1000);
    }
})
