









import Vue from 'vue';
import gsap from 'gsap';

export default Vue.extend({
    name: 'Cookies',
    methods: {
        cookiesBehaviourControl() {
            
            if( this.$refs.cookiesWrapper && !+(localStorage.getItem('krakenlabs-cookie-acepted') as string) ) {
                gsap.to( this.$refs.cookiesWrapper, .6, { transform: 'translateY(0)', opacity: 1} )
            } else 
                this.hideCookies();
        },
        hideCookies() {
            gsap.to( this.$refs.cookiesWrapper, 1, { transform: 'translateY(100%)', opacity: 0} )
            localStorage.setItem('krakenlabs-cookie-acepted', '1');
        }
    },
    mounted() {
        this.cookiesBehaviourControl();
    }
})
