export const testimonials = [
    {
        author: '- Dirección en Tecnicart',
        title: 'Juan Francisco Lledó',
        desc: 'Si lo que deseas es la mejor calidad, el mejor precio y la mejor atención, este es tu sitio. Completamente satisfechos con el trabajo que realizaron para nuestra empresa.'
    },
    {
        author: '- Gerente en Feli Davó joyeros',
        title: 'Feli Davo Alfonso',
        desc: 'Muy profesional en su trabajo, entrega de trabajo en plazo acordado, muy atento y servicial. Si necesitáramos otra vez de su ayuda, no dudaremos en volver a contactarlo.'
    },
    {
        author: '- Gerente en QGourmet',
        title: 'Antonio Santiago Portero',
        desc: 'Gran profesional. Hizo un gran trabajo en el plazo acordado. Recomendable 100%'
    },
    {
        author: '- Gerente en TPintxo',
        title: 'Alejandro Onteniente Sanchez',
        desc: 'Gran profesional y persona con grandes ideas que aportan gran capacidad de expansión. Total empatía y preocupación con cada empresa con la que trabaja. Totalmente recomendable'
    },
    {
        author: '- Cliente particular',
        title: 'Josep Fuentes',
        desc: 'Hoy en día es complicado encontrar un profesional de su nivel. Atención, asesoramiento, ejecución impecable...etc. Un crack en lo suyo!'
    },
    {
        author: '- Agente exclusiva en Generalli Seguros',
        title: 'Thais Gutierrez',
        desc: 'Hemos quedado muy satisfechos, tanto con el trabajo realizado, como con el tiempo y la atención. Si en el futuro fuera necesario, repetiríamos con él sin ninguna duda.'
    },
    {
        author: '- Cliente particular',
        title: 'Daniel Ferrandez Arenas',
        desc: 'Gran persona y gran profesional , muy satisfecho con el gran trabajo realizado. Muchas gracias y no dudaré en volver a solicitar tus servicios. Un saludo.'
    },
    {
        author: '- Cliente particular',
        title: ' Cristian Fuster Asencio',
        desc: 'Me ayudo mucho a desarrollar mi pagina web. Lo mejor, es que todo lo que explica, lo hace fácil!'
    },
    {
        author: '- Cliente particular',
        title: ' Andrés Serna',
        desc: 'Un servicio de confianza donde sabes que el proyecto va a quedar justo como deseabas o incluso mejor. Sin duda repetiremos la experiencia en un futuro.'
    }
]