export const works = [
    [
        {
            name: 'Manuel Manresa portfolio',
            techs: [
                'Vue 3',
                'GSAP',
                'TypeScript'
            ],
            excerpt: 'Web corporativa de agente exclosiva Generali',
            desc: "Portfolio de trabajos personales creado en Vue 3 + TypeScript con animaciones avanzadas + diseño exclusivo para el proyecto. <br> <a class='dark-link' target='_blank' href='https://www.manuelmanresa.dev/'>Visitar</a>",
            link: 'https://www.manuelmanresa.dev/',
            projectType: 'Web',
            img: require('@/assets/img/mmanresa-min.jpeg'),
            status: 'online'
        },
        {
            name: 'BespokeSpain',
            techs: [
                'php',
                'Wordpress',
                'javaScript'
            ],
            excerpt: 'Web corporativa para estudio agente inmobiliario',
            desc: "Portal de búsqueda inmobiliaria creado en Wordpress en la que los visitantes pueden obtener información de una gran variedad de propiedades ofertadas. Incluye filtros de búsqueda avanzados y panel de agente inmobiliario donde varios vendedores pueden gestionar diferentes propiedades disponibles. Permitr una <b>comunicación rápida con los visitantes mediante diversos canales</b> como WhatsApp y email. Con un diseño limpio y elegante, <b> preparación para SEO</b> y <b>analíticas detalladas</b> para un uso efectivo como herramienta de trabajo diaria. <br> <a class='dark-link' target='_blank' href='http://www.bespokespain.com/'>Visitar</a>",
            link: 'http://www.bespokespain.com/',
            projectType: 'Web',
            img: require('@/assets/img/bespoke.jpg'),
            status: 'online'
        },
        {
            name: 'Tecnicart',
            techs: [
                'php',
                'Wordpress',
                'javaScript',
                'gsap'
            ],
            excerpt: 'Web corporativa para estudio de arquitectura Tecnicart',
            desc: "Creada en Wordpress, Tecnicart se trata de un proyecto de web corporativa para estudio de arquitectura en la que los visitantes pueden obtener información rápida y completa de una gran variedad de servicios ofertados, permitiendo una <b>comunicación rápida con los visitantes mediante diversos canales</b> como WhatsApp y email. Con un diseño personalizado para el proyecto, limpio y elegante, menú principal con <b>animaciones avanzadas</b>,<b> preparación para SEO</b> y <b>analíticas detalladas</b> para un uso efectivo como herramienta de trabajo diaria. <br> <a class='dark-link' target='_blank' href='https://tecnicart.art/'>Visitar</a>",
            link: 'https://tecnicart.art/',
            projectType: 'Web',
            img: require('@/assets/img/tecnicart.jpg'),
            status: 'online'
        },
        {
            name: 'KLinks',
            techs: [
                'Vue 3',
                'TypeScript',
                'gsap',
                'php',
                'Laravel',
                'Web api'
            ],
            excerpt: 'WebApp de indexación de enlaces',
            desc: "Web app que permite el <b>registro de usuarios</b> y <b>la generación de portales</b> de enlaces donde unificar el acceso a redes y contenidos del usuario, permitiendo la personalización del portal según los gustos del usuario con diferentes temas adaptados a su contexto de negocio. <br> <a class='dark-link' target='_blank' href='https://klinks.krakenlabsweb.com/#/'>Visitar</a>",
            link: 'https://klinks.krakenlabsweb.com/#/',
            projectType: 'WebApp',
            img: require('@/assets/img/klinksh.jpg'),
            status: 'online'
        },
        {
            name: 'Seguros Thais Gutierrez',
            techs: [
                'php',
                'Wordpress',
                'javaScript',
                'chat'
            ],
            excerpt: 'Web corporativa de agente exclosiva Generali',
            desc: "Creada en Wordpress, Seguros Thais Gutierrez se trata de un proyecto de web corporativa en la que los visitantes pueden obtener información rápida y completa de una gran variedad de servicios ofertados, permitiendo una <b>comunicación rápida con los visitantes mediante diversos canales</b> como WhatsApp, email y chat directo. Con un diseño limpio y elegante,<b> preparación para SEO</b> y <b>analíticas detalladas</b> para un uso efectivo como herramienta de trabajo diaria. <br> <a class='dark-link' target='_blank' href='https://www.segurosthaisgutierrez.es/'>Visitar</a>",
            link: 'https://www.segurosthaisgutierrez.es/',
            projectType: 'Web',
            img: require('@/assets/img/desings-segurost.jpg'),
            status: 'online'
        },
        {
            name: 'App Las Vigías',
            techs: [
                'ionic framework',
                'Typescript',
                'Api Rest',
            ],
            excerpt: 'App de gestión de tiradas para club de tiro privado',
            desc: 'App Las Vigías ofrece una <b>app multiplataforma creada en Ionic Framework</b> y se trata de un proyecto de gestión privada del club deportivo para uso de los socios en el que los usuarios pueden <b>registrare como afiliados</b> y acceder a la información relevante de actualidad en el club. <br>Los usuarios son capaces de <b>inscribirse a competiciones realizadas por el club y acceder a los resultados de éstas</b> y así mantener una estrecha relación con el club.',
            link: '',
            projectType: 'Web App Multiplataforma',
            img: require('@/assets/img/design_design_tiradas.jpg'),
            status: 'App privada'
        },
        {
            name: 'Qgourmet café',
            techs: [
                'Font-awesome',
                'Wordpress',
                'php'
            ],
            excerpt: 'Web de empresa de Qgourmet café proveedores de hostelería',
            desc: "Qgourmetcafe se trata de la web corporativa de Qgourmet café, proveedores de hostelería que ofrece un gran nivel de atención con sus clientes. Mediante un <b>tema único y propio creado para Wordpress</b>, qgourmetcafe ofrece a sus clientes un punto de encuentro con <b>alto nivel de detalle</b> en el que éstos pueden obtener gran información de sus productos, facilitando el contacto y mejorando las relaciones con éstos. <br> <a class='dark-link' target='_blank' href='https://qgourmetcafe.com/'>Visitar</a>",
            link: 'https://qgourmetcafe.com/',
            projectType: 'Web',
            img: require('@/assets/img/design_qgourmetcafe.jpg'),
            status: 'online'
        },
        {
            name: 'TAP - Academy Pro',
            techs: [
            'Angular',
            'Typescript',
            'Api Rest',
            'Laravel',
            ],
            excerpt: 'Web app de gestión de clases particulares online en directo',
            desc: 'TAP se trata de una app web creada en <b>Angular en su versión cliente</b> y realizando comunicaciones con una <b>API creada en Laravel</b>. La app permite la creación de grupos de estudiantes y permite crear <b>clases en directo</b> para los alumnos de un mismo grupo. Ofrece <b>chat en tiempo real</b>, <b>gestión información de pagos</b>, <b>navegación por gestos</b> y <b>analíticas de uso</b> de la app, es multitema y ofrece diversidad de configuraciones.',
            link: '',
            projectType: 'Sistema e-learning Multiplataforma',
            img: require('@/assets/img/tap.png'),
            status: 'App privada'
        },
        {
            name: 'Web app Pro Wheels',
            techs: [
            'Angular',
            'Typescript',
            'Api Rest',
            'Laravel',
            ],
            excerpt: 'App de gestión de mantenimientos para talleres de bicicletas',
            desc: 'Pro Wheels se trata de una web app de gestión de talleres para bicicletas. En ella podemos ingresar a los datos de la empresa y <b>realizar gestiones de mantenimiento</b>, registrando empleados y asignando tareas a los empleados. Realiza <b>avisos a los clientes</b> una vez su revisión se ha terminado y mejora la confianza y la comunicación con los clientes para ofrecer un servició de alto nivel a un bajo coste.',
            link: '',
            projectType: 'Web App Multiplataforma',
            img: require('@/assets/img/login.jpg'),
            status: 'App privada'
        },
        {
            name: 'Tempus Geotagger',
            techs: [
            'Angular',
            'Typescript',
            'Gmaps'
            ],
            excerpt: 'Aplicación web de modificación de datos Exif y geolocalización de imágenes',
            desc: "Tempus GeoTagger se trata de una herramienta web para SEO local que permite la modificación de datos exif de nuestras imágenes. En ella podemos modificar los metadatos de geolocalización de nuestras imágenes, el título y el autor para mejorar el seo local y aumentar el posicionamiento orgánico del sitio web. <br> <a class='dark-link' target='_blank' href='https://geotag.tempuscode.com/'>Visitar</a>",
            link: 'https://geotag.tempuscode.com',
            projectType: 'Web / Herramienta',
            img: require('@/assets/img/design_geotagger.jpg'),
            status: 'online'
        },
        
        {
            name: 'Chats en tiempo real',
            techs: [
                'Angular',
                'Typescript',
                'Api Rest',
                'Laravel',
            ],
            excerpt: 'Funcionalidades de comunicación en tiempo real para uso interno en aplicaciones privadas',
            desc: 'Funcionalidades de comunicación en tiempo real para uso interno en aplicaciones privadas. Permite realizar <b>comunicaciones internas en tu sitio o aplicación</b> en tiempo real, con posibilidad de añadir <b>emoticonos, gifts y archivos adjuntos</b>. Ofrece un <b>diseño responsive</b> que permite ser usado en todos los dispositivos así como una interfaz agradable y de uso sencillo para los usuarios.',
            link: '',
            projectType: 'Herramienta',
            img: require('@/assets/img/chat.png'),
            status: 'Funcionalidades app privada'
        },
        {
            name: 'The Movie Doctor',
            techs: [
                'Angular',
                'Typescript',
                'Api'
            ],
            excerpt: 'Cartelera e información sobre películas y cine continuamente actualizada',
            desc: "The Movie Doctor es una herramienta web de consulta de información de películas que obtiene sus resultados de una <b>extensa base de datos</b>. En ella podemos consultar información acerca de una infinidad de películas, tanto de cartelera como de todos los tiempos y mantener esta información actualizada de forma automática sin ningún coste añadido. <br> <a class='dark-link' target='_blank' href='https://moviedoctor.tempuscode.com'>Visitar</a>",
            link: 'https://moviedoctor.tempuscode.com',
            projectType: 'Web',
            img: require('@/assets/img/design_movie.jpg'),
            status: 'online'
        },
        {
            name: 'Tempus Facture',
            techs: [
                'Angular',
                'Typescript',
                'Api Rest'
            ],
            excerpt: 'App web privada de gestión de facturas',
            desc: 'Tempus Facture se trata de una app web privada de gestión de facturas en la que permite llevar un registro con <b>asignación automática de faturas</b>, <b>almacenamiento de clientes</b> para creación de facturas rápidas y un <b>acceso multidispositivo</b> y en linea que permite al acceso, creación y modificación de las éstas <b>desde cualquier parte del mundo</b> mediante una conexión a internet',
            link: '',
            projectType: 'Web App Multiplataforma',
            img: require('@/assets/img/design_factures_mobile.jpg'),
            status: 'App privada'
        },
        {
            name: 'Urban Safe Shoes',
            techs: [
            'WooCommerce',
            'Wordpress',
            'php'
            ],
            excerpt: 'E-commerce de calzado de seguridad',
            desc: "Urban Safe Shoes se trata de un e-commerce de calzado de seguridad ultracómodo y válido para el dia a dia. <b>Creada mediante WooCommerce</b> permite una gestión total del control de inventario, disponibilidad, envíos, pagos, generación de facturas, entre otras muchas características. Permite una <b>comunicación efectiva con los clientes</b> y un <b>registro detallado de actividad</b> donde observar el comportamiento de los visitantes.  <br> <a class='dark-link' target='_blank' href='https://urbansafeshoes.com/'>Visitar</a>",
            link: 'https://urbansafeshoes.com/',
            projectType: 'Web e-commerce',
            img: require('@/assets/img/design_uss.jpg'),
            status: 'online'
        },
        {
            name: 'SoundSpot',
            techs: [
            'Angular',
            'Typescript',
            'Api',
            'Spotify'
            ],
            excerpt: 'SoundSpot spotify api, app de tendencias musicales de spotify',
            desc: 'SoundSpot se trata de una herramienta web que consulta datos directamente de la <b>API de Spotify</b>, permitiendo obtener información detallada y precisa tanto de los últimos <b>exitos musicales</b> como de toda la biblioteca de Spotify, ofreciendo información que permanece siempre actualizada de forma automática y con un diseño agradable para el visitante.',
            link: '',
            projectType: 'Web',
            img: require('@/assets/img/design_soundspot.jpg'),
            status: 'App privada'
        },
        {
            name: 'Decogaia',
            techs: [
            'WooCommerce',
            'Wordpress',
            'php'
            ],
            excerpt: 'E-commerce de productos para el hogar con ambiente natural',
            desc: "Decogaia es una tienda online de articulos para el hogar en un ambiente natural, agradable y moderno. Ofrece un diseño estilizado para los visitante y obtiene datos de grandes proveedores de productos donde encontramos una gran variedad de oferta posible para mostrar a los clientes. Permite la realización de pagos mediante PayPal y tarjeta de credito. <br> <a class='dark-link' target='_blank' href='https://decogaia.com'>Visitar</a>",
            link: 'https://decogaia.com',
            projectType: 'Web e-commerce',
            img: require('@/assets/img/design_decogaia.jpg'),
            status: 'online'
        },
        {
            name: 'Más...',
            techs: [
                'Angular',
                'Vue Js',
                'JavaScript',
                'TypesScript',
                'Gsap',
                'NodeJs',
                'Laravel',
                'WooCommerce',
                'Wordpress',
                'php',
                'CSS',
                '...',
            ],
            excerpt: 'Colaboraciones para proyectos con empresas externas',
            desc: 'Entre muchos de los trabajos de Kraken Labs se encuentran <b>colaboraciones y trabajos realizados a empresas externas</b> para creación de proyectos de terceros. De esta forma si tienes una <b>agencia de desarrollo o marketing online</b> puedes seguir ofreciendo a tus clientes sitios y aplicaciones web con un <b>alto nivel de detalle en el usuario</b>, desde Kraken Labs recibiras el apoyo para la realización de los sitios web y un trato personalizado para tu empresa.',
            link: '#',
            projectType: 'Web / Web App / e-commerce',
            img: require('@/assets/img/setup.jpg'),
            status: 'App privada'
        }
    ]
  ];