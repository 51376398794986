



























































import { Component, Vue } from 'vue-property-decorator';
import KrakenButton from '@/components/buttons/Kraken-button.vue';
import SkewBand from '@/components/ui/SkewBand.vue';
import MainTitle from '@/components/texts/MainTitle.vue';
import LogoTitle from '@/components/texts/LogoTitle.vue';
import QuotesImage from '@/components/ui/QuotesImage.vue';
import ProjectsList from '@/components/interaction/ProjectsLists.vue';
import ContactBanner from '@/components/interaction/ContactBanner.vue';
import Testimonials from '@/components/ui/testimonials/Testimonials.vue';
import Footer from '@/components/parts/Footer.vue';
import GrowingBranches from '@/components/ui/GrowingBranches.vue';

import { testimonials } from '@/data/testimonials';

import { vw } from '@/utils/deviceDetect.ts';

import gsap, { Power3 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin( ScrollTrigger );

@Component({
  components: {
    KrakenButton,
    SkewBand,
    MainTitle,
    QuotesImage,
    LogoTitle,
    ProjectsList,
    Testimonials,
    ContactBanner,
    GrowingBranches,
    Footer
  },
  data() {
    return {
      testimonials
    }
  }
  
})
export default class Home extends Vue {

  animateScrollMove() {
    this.animateTitle();
    this.animateTentacles();

    if( vw > 767 )
      this.animateGrowingBranches();

  }

  animateTentacles(){
    const tentacles = document.querySelector('.main-tentacles-wrapper') as HTMLDivElement;
    gsap.from( tentacles, {
      scrollTrigger: {
        trigger: tentacles,
        start: 'top 40%',
        end: 'bottom 40%',
        toggleActions: 'restart pause reverse pause',
        scrub: true
      },
      y: vw < 767 ? -300 : -50,
      opacity: 0,
      duration: 3,
      ease: 'none'
    });
  }

  animateTitle(){
    const title = document.querySelector('.home-second-title') as HTMLDivElement;
    gsap.from( title, {
      scrollTrigger: {
        trigger: title,
        start: 'top 100%',
        end: 'bottom 80%',
        toggleActions: 'restart pause reverse pause',
        scrub: true,
      },
      y: 100,
      duration: 3,
      ease: 'none'
    });
  }

  animateGrowingBranches() {
    const growingBranchesLogo    = document.querySelector('.growingbranches-img') as HTMLDivElement;
    const growingBranchesWrapper = document.querySelector('.growingbranches-wrapper') as HTMLDivElement;
    const growingBranchesElements = [...document.querySelectorAll('.growingbranches-step')] as HTMLDivElement[];
    const tl = gsap.timeline();

    tl.to( growingBranchesLogo, {
      scrollTrigger: {
        trigger: growingBranchesLogo,
        start: 'top 50%',
        end: 'bottom 15%',
        toggleActions: 'restart pause reverse pause',
        scrub: true,
      },
      scale: .3,
      duration: 1,
      ease: 'none'
    });

    tl.to( growingBranchesLogo, {
      scrollTrigger: {
        trigger: growingBranchesLogo,
        start: 'top 50%',
        endTrigger: growingBranchesWrapper,
        end: 'bottom 60%',
        toggleActions: 'restart pause reverse pause',
        scrub: true,
        pin: true
      },
      y: 100,
      opacity: 0,
      duration: 3,
      ease: 'none'
    });

    growingBranchesElements.forEach( elm => {
      gsap.to( elm, {
        scrollTrigger: {
          trigger: elm,
          start: 'top 60%',
          toggleActions: 'restart pause reverse pause',
        },
        opacity: 1,
        duration: 1,
        ease: Power3.easeOut
      });
    })
    
  }

  mounted() {
    setTimeout(() => {
      this.animateScrollMove();
    }, 1000);
  }

}
