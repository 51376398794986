












import Vue from 'vue';
import gsap, { Power2, Elastic } from 'gsap';

export default Vue.extend({
    name: 'LogoTitle',
    data() {
        return {
            logoSrc: require(`@/assets/img/aro-kraken.png`)
        }
    },
    methods: {
        animateLogo() {
            const logoWrapperElement  = document.querySelector('.logotitle-wrapper');
            const logoImgElement      = document.querySelector('.logotitle-img');
            const logoSubtitleElement = document.querySelector('.logotitle-span-subtitle');

            const tl = gsap.timeline();
            tl.to(logoWrapperElement, 3.6, {transform: 'scale(1)  translate(0, 0)', webkitTransform: 'scale(1)  translate(0, 0)', ease: Power2.easeOut}, 0)
            tl.to(logoImgElement, .8, {bottom: 0, ease: Elastic.easeOut}, '>')
            tl.to(logoSubtitleElement, .8, {left: '101.5%', ease: Power2.easeOut}, '-=.5')
        }
    },
    mounted() {
        this.animateLogo();
    }
})
